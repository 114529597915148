import SignupLayout from '../components/layout/SignupLayout'
import { LoginContextProvider } from '@/context/login'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PublicGuard from '@/guard/public'
import { useEffect } from 'react'


export default function SignupPage(props) {
	useEffect(async () => {
	}, [])

	return (
		<LoginContextProvider>
			<PublicGuard>
				<GoogleReCaptchaProvider
					reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
					<SignupLayout>{props.children}</SignupLayout>
				</GoogleReCaptchaProvider>
			</PublicGuard>
		</LoginContextProvider>
	)
}
