export default function SubNav() {
	return (
		<div className="bg-primary-500 relative overflow-hidden">
			<div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
				<div className="relative h-full">
					<svg
						className="absolute right-full transform translate-y-48 translate-x-3/4"
						width="704"
						height="784"
						fill="none"
						viewBox="0 0 404 784">
						<defs>
							<pattern
								id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
								x="0"
								y="0"
								width="20"
								height="20"
								patternUnits="userSpaceOnUse">
								<rect
									x="0"
									y="0"
									width="4"
									height="4"
									className="text-primary-800"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width="404"
							height="784"
							fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
						/>
					</svg>
					<svg
						className="absolute left-full top-32 transform lg:-translate-x-3/4"
						width="604"
						height="584"
						fill="none"
						viewBox="0 0 404 584">
						<defs>
							<pattern
								id="d2a68204-c383-44b1-b99f-42ccff4e5365"
								x="0"
								y="0"
								width="20"
								height="20"
								patternUnits="userSpaceOnUse">
								<rect
									x="0"
									y="0"
									width="4"
									height="4"
									className="text-primary-800"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width="404"
							height="784"
							fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
						/>
					</svg>
				</div>
			</div>

			<div className="max-w-7xl mx-auto px-4 sm:px-6">
				<div className="h-64"></div>
			</div>
		</div>
	)
}
