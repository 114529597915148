import Tippy from '@tippyjs/react'
import { useEffect, useState } from 'react'
import 'tippy.js/dist/tippy.css'

export default function Tooltip(props) {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (props.lock) {
			setVisible(true)
		} else {
			if (props.visible) {
				setVisible(true)
			} else {
				setVisible(false)
			}
		}
		return () => {}
	}, [props.visible, props.lock])

	return (
		<>
			{props.content ? (
				<>
					{props.auto ? (
						<Tippy interactive={true} content={props.content}>
							{props.children}
						</Tippy>
					) : (
						<Tippy interactive={true} visible={visible} content={props.content}>
							{props.children}
						</Tippy>
					)}
				</>
			) : null}
		</>
	)
}
