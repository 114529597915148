import { LoginContext } from '@/context/login'
import { useContext, useEffect } from 'react'
import { AuthContext } from '@/context/auth'
import Loader from '../ui/Loader'
import Sleep from '@/lib/sleep'
import { b64Decode } from '@/lib/b64'
import { useHistory } from 'react-router-dom'

export default function AuthProcess() {
	const [loginState] = useContext(LoginContext)
	const [, authDispatch] = useContext(AuthContext)
	const router = useHistory()

	useEffect(async () => {
		const query = new URLSearchParams(router.location.search)

		authDispatch({
			type: 'SET_AUTH',
			payload: {
				user: {
					email: loginState.auth.email,
					account_type: loginState.auth.account_type,
					session_id: loginState.auth.session_id,
					firstname: loginState.auth.firstname,
					lastname: loginState.auth.lastname,
					profile_pic: loginState.auth.profile_pic,
				},
				iam: loginState.auth.iam,
				authenticated: true,
			},
		})

		await Sleep(1500)

		const redirect_uri = query.get('redirect_uri')
		if (redirect_uri) {
			const pathName = b64Decode(redirect_uri)
			if (pathName !== '') {
				router.push(pathName)
			} else {
				router.push('/')
			}
		} else {
			router.push('/')
		}
		return () => {}
	}, [])

	return (
		<>
			<div className='mx-auto text-center mt-5'>
				<Loader color='primary-500' size='maxi'></Loader>
				<p className='text-primary-500 mt-4 mb-5'>Authentication</p>
			</div>
		</>
	)
}
