export default function Label(props) {
	let font = 'medium'

	if (props.font) {
		font = props.font
	}

	return (
		<span className={`text-sm font-${font} text-gray-700`}>{props.value}</span>
	)
}
