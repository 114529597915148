import Tooltip from './Tooltip'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'
import Loader from './Loader'
import { useEffect, useState } from 'react'
import Sleep from '@/lib/sleep'

export default function ActionButton(props) {
	let [visible, setVisible] = useState(false)
	let [lock, setLock] = useState(false)

	let [theme] = useState({
		default: {
			color: 'gray-500',
		},
		success: {
			color: 'green-500',
		},
		error: {
			color: 'red-500',
		},
	})

	useEffect(async () => {
		let duration = 5000

		if (props.duration) {
			duration = props.duration
		}
		if (props.status.type == 'success' || props.status.type == 'error') {
			setLock(true)
			await Sleep(duration)
			setVisible(false)
			setLock(false)
			props.onFinish()
		}
		return () => {}
	}, [props.status.type])

	return (
		<>
			{props.loading ? (
				<span className="ml-1">
					<Loader size="mini" color="gray-500"></Loader>
				</span>
			) : (
				<Tooltip
					lock={lock}
					visible={visible}
					content={<span>{props.status.message}</span>}>
					<span
						onMouseEnter={() => setVisible(true)}
						onMouseLeave={() => setVisible(false)}
						onClick={props.onClick}>
						<FontAwesomeIcon
							className={`text-md cursor-pointer ${
								theme[props.status.type].color
							} text-xs ml-1`}
							icon={props.status.icon}
						/>
					</span>
				</Tooltip>
			)}
		</>
	)
}
