function b64Encode(str) {
	let result = ''
	try {
		result = window.btoa(unescape(encodeURIComponent(str)))
	} catch {
		result = ''
	}

	return result
}

function b64Decode(str) {
	let result = ''
	try {
		result = decodeURIComponent(escape(window.atob(str)))
	} catch {
		result = ''
	}

	return result
}

module.exports = {
	b64Encode,
	b64Decode,
}
