import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'
import {
	faExclamationCircle,
	faCheckCircle,
	faMailbox,
	faShieldAlt,
	faThumbtack,
} from '@arcane-ui/pro-duotone-svg-icons'

export default function Alert(props) {
	let type = props.type

	let theme = {
		success: {
			bg: 'bg-green-100',
			icon: faCheckCircle,
			title: 'text-green-800',
			iconColor: 'text-green-500',
		},
		error: {
			bg: 'bg-red-100',
			icon: faExclamationCircle,
			title: 'text-red-800',
			iconColor: 'text-red-500',
		},
		email: {
			bg: 'bg-gray-200',
			icon: faMailbox,
			title: 'text-gray-800',
			iconColor: 'text-primary-500',
		},
		pin: {
			bg: 'bg-orange-100',
			icon: faThumbtack,
			title: 'text-orange-500',
			iconColor: 'text-orange-500',
		},
		warning: {
			bg: 'bg-orange-100',
			icon: faExclamationCircle,
			title: 'text-orange-500',
			description: 'gray-900',
			iconColor: 'text-orange-500',
		},
		'2FA': {
			bg: 'bg-gray-200',
			icon: faShieldAlt,
			title: 'text-gray-800',
			iconColor: 'text-primary-500',
		},
	}

	if (!theme[type]) {
		type = 'success'
	}

	const alert = theme[type]

	return (
		<>
			<div
				className={`rounded-xl ${alert.bg} p-3 mb-3 mx-0 ${props.className}`}>
				<div className="flex items-center">
					<div>
						<p>
							<FontAwesomeIcon
								className={`text-md mx-2 mr-3 ${alert.iconColor}`}
								icon={alert.icon}
							/>
						</p>
					</div>
					<div>
						<h3 className={`text-sm font-medium ${alert.title}`}>
							{props.title}
						</h3>
						<h3 className={`text-sm font-regular ${alert.description}`}>
							{props.description}
						</h3>
					</div>
				</div>
			</div>
		</>
	)
}
