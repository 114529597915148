import useDigitInput from '@/lib/digits'
import { forwardRef } from 'react'

const DigitInputElement = forwardRef(({ ...props }, ref) => {
	return (
		<label className="digit-label">
			<div className="digit">{props.value}</div>
			<hr className="digit-hr" />
			<input className="digit-input" {...props} ref={ref} inputMode="decimal" />
		</label>
	)
})

export default function InputDigits(props) {
	const [value, onChange] = props.value
	const digits = useDigitInput({
		acceptedCharacters: /^[0-9]$/,
		length: 6,
		value,
		onChange,
	})

	return (
		<div className="input-group">
			<DigitInputElement autoFocus {...digits[0]} />
			<DigitInputElement {...digits[1]} />
			<DigitInputElement {...digits[2]} />
			<DigitInputElement {...digits[3]} />
			<DigitInputElement {...digits[4]} />
			<DigitInputElement {...digits[5]} />
		</div>
	)
}

DigitInputElement.displayName = 'DigitInputElement'