import { useReducer, createContext } from 'react'

export const AuthContext = createContext()

const initialState = {
	user: {
		email: 'florian.berthier@synaps.io',
		firstname: '',
		lastname: '',
		profile_pic: '',
	},
	iam: {
		admin: false,
		residency: false,
		identity: false,
		aml: false,
		corporate: false,
		facematch: false,
		dupplicate: false,
	},
	authenticated: false,
	online_socket: {},
}

function setAuth(auth) {
	const newIAM = setIAM(auth.iam)
	auth.iam = newIAM
	return auth
}

function setIAM(iam) {
	return {
	}
}

const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_AUTH':
		return setAuth(action.payload)
	default:
		return state
	}
}

export const AuthContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<AuthContext.Provider value={[state, dispatch]}>
			{props.children}
		</AuthContext.Provider>
	)
}
